<template>
  <div class="container mx-auto max-w-md shadow-md hover:shadow-lg transition duration-300">
    <modal
      v-if="isModalDisplaying" 
      text="Ben je zeker dat je een beurt wilt afwaarderen?" 
      title="Afwaarderen"
      leftButton="annuleren" 
      rightButton="verwijder" 
      @cancelButtonClicked="hideModal()" 
      @confirmButtonClicked="consume()" 
    />
    <div class="py-0 lg:py-12 p-10 bg-white rounded-xl">
      <form @submit.prevent="onSubmit">
        <label class="mr-4 text-gray-700 font-bold inline-block mb-2" for="name">Lidkaart</label>
        <input
          v-model="membercard"
          id="membercard"
          name="membercard"
          type="text"
          autofocus="on"
          autocomplete="off"
          v-maska="'#### #### ####'"
          class="w-full border bg-gray-100 py-2 px-4 outline-none focus:ring-2 focus:ring-indigo-400 rounded"
        />
        <button type="submit" class="w-full mt-6 mb-6 text-indigo-50 font-bold bg-indigo-600 py-3 rounded-md hover:bg-indigo-500 transition duration-300">
          <span v-show="!isSubmitting">verzenden</span>
          <div v-show="isSubmitting" class="flex justify-center items-center space-x-1 text-sm text-white-700">
            <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
              <path clip-rule='evenodd' d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z' fill='currentColor' fill-rule='evenodd' />
            </svg>
          </div>
        </button>
      </form>
    </div>
  </div>

  <div v-show="isConsumed" class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-md text-green-100 bg-green-700 border border-green-700 ">
    <div class="text-xl font-normal  max-w-full flex-initial">
      <div class="py-2">Success
          <div class="text-sm font-base">De beurt is afgewaardeerd.</div>
      </div>
    </div>
  </div>


  <div class="flex flex-col pt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <h1 class="text-3xl pt-2 pl-4">Abonnementen en beurtenkaarten</h1>
          <table class="text-center container mx-auto mt-3">
            <thead class="bg-gray-50 border-b">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Naam
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Abonnement
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Credits
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Vervaldatum
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Acties</span>
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="journal in journals" v-bind:key="journal.id" class="mb-12 lg:mb-0">
              <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Naam">
                <div class="text-sm font-medium text-gray-900">
                  {{ journal.customer.label }}
                </div>
              </td>
              <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Abonnement">
                <div class="text-sm text-gray-500">
                  {{ journal.journalType.code  }}
                </div>
              </td>
              <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Credits">
                <div class="text-sm text-gray-900">
                  {{ journal.credits  }}
                </div>
              </td>
              <td class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Vervaldatum">
                <div class="text-sm text-gray-900">
                  {{ displayEndDate(journal) }}
                </div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button 
                    class="w-full text-indigo-50 font-bold bg-indigo-600 py-3 rounded-md hover:bg-indigo-500 transition duration-300"
                    @click="displayModal(journal.id)"
                    v-if="canJournalBeConsumed(journal)"
                >
                  <span v-show="!isConsumeSubmitting" class="text-white-600 hover:text-white-900" data-label="Acties">
                    Afwaarderen
                  </span>
                  <div v-show="isConsumeSubmitting" class="flex justify-center items-center space-x-1 text-sm text-white-700">
                    <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                      <path
                        clip-rule='evenodd'
                        d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                        fill='currentColor'
                        fill-rule='evenodd' />
                    </svg>
                  </div>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col pt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <h1 class="text-3xl pt-2 pl-4">Historiek</h1>
          <table class="text-center container mx-auto mt-3">
            <thead class="bg-gray-50 border-b">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tijdstip
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                A-kaart
              </th>
              <th scope="col" class="relative px-6 py-3">
                <span class="sr-only">Acties</span>
              </th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="historyItem in history" v-bind:key="historyItem.timestamp" class="mb-12 lg:mb-0">
              <td class="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Naam">
                <div class="text-sm font-medium text-gray-900">
                  {{ formatTimestamp(historyItem.timestamp) }}
                </div>
              </td>
              <td class="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" data-label="Abonnement">
                <div class="text-sm text-gray-500">
                  {{ formatMembercard(historyItem.membercard) }}
                </div>
              </td>
              <td class="px-6 py-1 whitespace-nowrap text-right text-sm font-medium">
                <button
                  class="px-6 py-3 text-indigo-50 font-bold bg-indigo-600 rounded-md hover:bg-indigo-500 transition duration-300"
                  @click="getJournals(historyItem.membercard)">
                    Opnieuw zoeken
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from "vue"
import useJournals from "../useJournals"
import moment from 'moment'
import Modal from "./Modal.vue"

export default {
  name: "Journals",
  components: {'modal': Modal},
  props: {
    msg: String,
  },
  setup() {
    const { 
      getJournals, 
      journals, 
      resetJournals, 
      consumeJournal, 
      isSubmitting, 
      isConsumeSubmitting, 
      history,
      canJournalBeConsumed,
      displayEndDate } = useJournals()
    const membercard = ref("")
    const journalToBeConsumed = ref("")
    const isModalDisplaying = ref(false)
    const isConsumed = ref(false)

    const onSubmit = () => {
      membercard.value = membercard.value.replace(/\s/g, '')
      console.log(membercard.value)
      getJournals(membercard.value)
      isConsumed.value = false
    }

    const consume = () => {
      console.log("consume")
      consumeJournal(journalToBeConsumed.value)
      hideModal()
      console.log("hide journals")
      hideJournals()
      isConsumed.value = true
      membercard.value = ""
    }

    const formatTimestamp = (timestamp) => {
      return moment(timestamp).format("YYYY-MM-DD hh:mm:ss")
    }
 
    const formatMembercard = (membercard) => {
      return membercard.substr(0,4) + " " + membercard.substr(4,4) + " " + membercard.substr(8,4)
    }

    const displayModal = (journalId) => {
      console.log('display modal')
      isModalDisplaying.value = true
      journalToBeConsumed.value = journalId
      console.log(isModalDisplaying.value)
      console.log(journalToBeConsumed.value)
    }

    const hideModal = () => {
      isModalDisplaying.value = false
    }

    const hideJournals = () => {
      console.log('reset journals')
      resetJournals()
    }

    return {
      onSubmit,
      membercard,
      journals,
      consume,
      isSubmitting,
      isConsumeSubmitting,
      formatTimestamp,
      formatMembercard,
      journalToBeConsumed,
      isModalDisplaying,
      displayModal,
      hideModal,
      isConsumed,
      history,
      getJournals,
      canJournalBeConsumed,
      displayEndDate
    }
  },
}
</script>

<style scoped></style>
