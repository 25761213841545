<template>
  <div class="container mx-auto flex flex-col items-center">
    <form @submit.prevent="onSubmit" class="shadow-lg w-80 p-4 flex flex-col bg-white rounded-lg">
      <input 
        type="text" 
        placeholder="username" 
        class="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500"
        v-model="username"
       />
      <input 
        type="password" 
        placeholder="Pasword" 
        class="mb-3 py-3 px-4 border border-gray-400 focus:outline-none rounded-md focus:ring-1 ring-cyan-500"
        v-model="password"
       />
      <button class="w-full mt-6 text-indigo-50 font-bold bg-indigo-600 py-3 rounded-md hover:bg-indigo-500 transition duration-300">
          <span v-show="!isSubmitting">inloggen</span>
          <div v-show="isSubmitting" class="flex justify-center items-center space-x-1 text-sm text-white-700">
            <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
              <path clip-rule='evenodd' d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z' fill='currentColor' fill-rule='evenodd' />
            </svg>
          </div>
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue"
import useAuth from "../useAuth"

export default {
  name: "Login",
  props: {
    msg: String,
  },
  setup() {
    const { login, isLoggedIn, isSubmitting } = useAuth()
    const username = ref("")
    const password = ref("")
    console.log(isLoggedIn)

    const onSubmit = () => {
      login(username.value, password.value)
    }

    return {
      onSubmit,
      username,
      isLoggedIn,
      password,
      isSubmitting
    }
  },
}
</script>
