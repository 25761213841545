import { ref, watch } from "vue"
import axios from "axios"
import moment from 'moment'

// @todo fix this quick and dirty way
const backendUri = require("../env/env_vars.json").backend_uri

const useJournals = () => {
  const journals = ref([])
  const history = ref([])
  const isSubmitting = ref(false)
  const isConsumeSubmitting = ref(false)

  watch(
    () => isConsumeSubmitting.value,
    (to) => {
      console.log("watcher: isConsumeSubmitting from:" + isConsumeSubmitting.value)
      console.log("watcher: isConsumeSubmitting to:" + to)

      if (to == false) {
        console.log("reloading list")
      }
    },
    { deep: true }
  )

  const getJournals = (membercard) => {
    journals.value = []
    isSubmitting.value = true
    axios.get(
      backendUri+"/api/customer/journals",
      {
        params: { member_card: membercard },
        headers: { Authorization: "Bearer " + localStorage.accessToken },
      }
    ).then((response) => {
      console.log(response.data)
      journals.value = response.data
      history.value.unshift({
        "timestamp": new Date(),
        "membercard": membercard,
      })

      if (history.value.length > 5) {
        history.value.pop()
      }

      isSubmitting.value = false
    })
  }

  const resetJournals = () => {
    journals.value = []
  }

  const consumeJournal = (journalId) => {
    isConsumeSubmitting.value = true
    axios(
    {
      method: 'post',
      url: backendUri+"/api/journal/"+journalId+"/consume",
      headers: { Authorization: "Bearer " + localStorage.accessToken },
      data: { credits: 1 }
    }
    ).then((response) => {
      isConsumeSubmitting.value = false
      console.log(response.data)
    })
  }

  const canJournalBeConsumed = (journal) => {
    const hasCredits = Object.prototype.hasOwnProperty.call(journal, 'credits')
    const hasExpirationDate = Object.prototype.hasOwnProperty.call(journal, 'expiration')
    const hasTerminationDate = Object.prototype.hasOwnProperty.call(journal, 'termination')

    if (!(hasCredits || (hasExpirationDate || hasTerminationDate))) {
      return false
    }

    if (hasCredits && journal.credits == 0) {
      return false
    }

    if (hasTerminationDate) {
      const terminationDate = new Date(journal.termination)
      if (terminationDate < getCurrentDate()) {
        return false
      }
    }

    if (hasExpirationDate) {
      const expirationDate = new Date(journal.expiration)
      if (expirationDate < getCurrentDate()) {
        return false
      }
    }

    return true
  }

  const getCurrentDate = () => {
    return new Date()
  }

  const getDate = (dateString) => {
    return new Date(dateString)
  }

  const displayEndDate = (journal) => {
    const hasExpirationDate = Object.prototype.hasOwnProperty.call(journal, 'expiration')
    const hasTerminationDate = Object.prototype.hasOwnProperty.call(journal, 'termination')
   
    if (hasExpirationDate && !hasTerminationDate) {
      return formatDate(journal.expiration)
    }

    if (!hasExpirationDate && hasTerminationDate) {
      return formatDate(journal.termination)
    }

    if (hasExpirationDate && hasTerminationDate) {
      if (journal.termination < journal.expiration) {
        return formatDate(journal.termination)
      } else {
        return formatDate(journal.expiration)
      }
    }
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }


  return {
    getJournals,
    resetJournals,
    consumeJournal,
    journals,
    isSubmitting,
    isConsumeSubmitting,
    history,
    canJournalBeConsumed,
    getDate,
    getCurrentDate,
    displayEndDate,
    formatDate
  }
}

export default useJournals
