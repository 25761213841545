<template>
  <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
  <Logout v-if="isLoggedIn"/>
  <Login v-if="!isLoggedIn" />
  <Journals v-else />
</template>

<script>
import Logout from "./components/Logout.vue"
import Login from "./components/Login.vue"
import Journals from "./components/Journals.vue"
import useAuth from "./useAuth"

export default {
  name: "App",
  components: {
    Login,
    Logout,
    Journals,
  },
  setup() {
    const { isLoggedIn } = useAuth()

    return {
      isLoggedIn,
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* ----- RESPONSIVE TABLE ----- */
@media screen and (max-width: 1024px) {
  table {
    border: 0;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: .625em;
    width: auto;
  }
  table > tbody > tr > td,
  table > tbody > tr > td > div {
    display: block;
    text-align: right;
    width: content-box;
    overflow: hidden;
    margin-right: -15px;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    font-size: 14px;
    margin-top: 2px;
    margin-left: -15px;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
/* --- RESPONSIVE TABLE-END --- */
</style>
