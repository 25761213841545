<template>
  <div class="container mx-auto flex flex-col items-center">
    <form @submit.prevent="onSubmit" class="shadow-lg w-80 p-4 flex flex-col bg-white rounded-lg">
      <button class="w-full mt-6 text-indigo-50 font-bold bg-indigo-600 py-3 rounded-md hover:bg-indigo-500 transition duration-300">
          <span>afmelden</span>
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue"
import useAuth from "../useAuth"

export default {
  name: "Logout",
  props: {
    msg: String,
  },
  setup() {
    const { logout, isLoggedIn, isSubmitting } = useAuth()
    const username = ref("")
    const password = ref("")
    console.log(isLoggedIn)

    const onSubmit = () => {
      logout()
    }

    return {
      onSubmit,
      username,
      isLoggedIn,
      password,
      isSubmitting
    }
  },
}
</script>
