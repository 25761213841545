import { ref, watch } from "vue"
import axios from "axios"
const isLoggedIn = ref(false)

// @todo fix this quick and dirty way
const backendUri = require("../env/env_vars.json").backend_uri

const checkIfLoggedIn = (expiration) => {
  var d = new Date()
  var expirationDate = new Date(expiration)
  console.log(d)
  console.log(expirationDate)
  if (d < expirationDate) {
    return true
  }

  return false
}

const useAuth = (key = "expiration") => {
  let init = localStorage.getItem(key)
  const expiration = ref(init)
  const isSubmitting = ref(false)
  isLoggedIn.value = checkIfLoggedIn(expiration.value)

  watch(
    () => expiration.value,
    (to) => {
      console.log("watcher: " + expiration.value)
      localStorage.setItem(key, to)
      isLoggedIn.value = checkIfLoggedIn(expiration.value)
    },
    { deep: true }
  )

  const login = (username, password) => {
    isSubmitting.value = true
    var postData = "client_id=1-2-3-4_slightlylesssecretpublicid"
    postData = postData + "&grant_type=password"
    postData = postData + "&username=" + username
    postData = postData + "&password=" + password
    postData = postData + "&client_secret=supersecretsecretphrase"

    axios
      .post(
        backendUri+"/oauth/v2/token",
        postData
      )
      .then((response) => {
        localStorage.accessToken = response.data.access_token
        var d = new Date()
        var expirationDate = new Date(
          d.getTime() + response.data.expires_in * 1000
        )
        // localStorage.expiration = expirationDate
        expiration.value = expirationDate
        isSubmitting.value = false
        console.log(response.data)
      })
  }

  const logout = () => {
    expiration.value = ''
    isLoggedIn.value = false
  }

  return { login, logout, isLoggedIn, isSubmitting }
}

export default useAuth
